<template>
  <div v-if="event && race && result">
    <v-breadcrumbs v-if="!isEmbedded" :items="breadcrumbItems"/>
    <v-card :elevation="isEmbedded ? 0 : 4">
      <!-- <EventHeader :event="event" :title="race.name" /> -->
      <div v-if="race && result && race.route" class="mt-0 mb-8">
        <!-- <h3 class="mx-4">{{$t('events.race.course-map-title')}}</h3> -->
        <v-alert type="info" tile class="mb-0">You can drag the blue marker or use the arrows in street view to move around.</v-alert>
        <RaceResultsMap ref="resultMap" :event="event" :race="race" :meta="meta" :showResultPopup="false" height="300px" class="mapContainer" @mapLoaded="tryLoadMap"  />
        <div ref="streetviewPlaceholder" class="streetviewContainer"/>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import eventService from "@/services/eventService";
import EventHeader from "@/components/EventHeader.vue";
import ShareButton from "@/components/ShareButton";
import RaceResultsMap from "@/components/RaceResultsMap.vue";
import resultColumns from '@/util/resultColumns.js'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "RaceResultStreetView",
  components: {
    EventHeader,
    RaceResultsMap,
    ShareButton,
  },
  props: {
    embed: Boolean,
  },
  data() {
    return {
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      eventId: null,
      raceId: null,
      resultId: null,
      event: null,
      race: null,
      result: null,
      meta: null,
      marker: null,
      badges: null,
    };
  },
  async mounted() {
    this.eventId = this.eventId || this.$route.params.id;
    this.raceId = this.raceId || this.$route.params.raceId;
    this.resultId = this.resultId || this.$route.params.resultId;
    await this.loadData();
  },
  methods: {
    async open(eventId, raceId, resultId) {
      this.eventId = eventId;
      this.raceId = raceId;
      this.resultId = resultId;
      this.loadData();
    },
    async loadData() {
      this.event = (await eventService.get(this.eventId)).data;
      this.race = this.event.races.find(x => x.id === this.raceId);
      this.result = (await eventService.getRaceResultDetails(this.eventId, this.raceId, this.resultId)).data;
      this.badges = (await eventService.getRaceBadges(this.eventId, this.raceId)).data.data;

      if (this.race && this.race.dist && this.$refs.resultMap && this.$refs.resultMap.map) {
        this.$refs.resultMap.ensureBadgesLoaded(this.badges);
        if (this.marker) {
          this.marker.setZIndexOffset(100);
        }
      }
    },
    async tryLoadMap() {
      console.log('tryLoadMap', this.result, this.$refs.resultMap);
      if (this.result == null || this.$refs.resultMap == null) {
        return;
      }
      console.log('loading result on map now', this.badges);
      //this.$refs.resultMap.loadResults([this.result], this.meta);
      if (this.race && this.race.dist) {
        this.$refs.resultMap.ensureBadgesLoaded(this.badges);

        var scaledDist = (this.result.score_value / this.race.dist) * this.$refs.resultMap.distOverCourse;
        var latLng = this.$refs.resultMap.getLatLngAtDistance(this.$refs.resultMap.courseLatLngs, scaledDist);
        console.log('loading result on map now', this.result.score_value, this.race.dist, scaledDist, latLng);
        this.loadStreetView(latLng.lat, latLng.lng);

        this.marker = new L.marker(latLng, {draggable:'true', riseOnHover: true, icon: this.$refs.resultMap.markerIcon, title: 'Drag streetview location'}).addTo(this.$refs.resultMap.map);
        const self = this;
        this.marker.on('dragend', function(event){
          var marker = event.target;
          var position = self.getNearestPointToPolyline(marker.getLatLng(), self.$refs.resultMap.courseLatLngs);
          //var distOverCourse = self.getDistanceTillCoord(self.courseLatLngs, position);
          //var totalCourse = self.getDistanceTillCoord(self.courseLatLngs, {});
          //var meters = Math.round((distOverCourse/this.$refs.resultMap.distOverCourse) * (self.race.dist));
          //console.log('distance over course', meters, 'm, score value:', self.value);
          marker.setLatLng(new L.LatLng(position.lat, position.lng));
          self.$refs.resultMap.map.panTo(new L.LatLng(position.lat, position.lng));
          self.loadStreetView(position.lat, position.lng);
        });

      }
    },

    getNearestPointToPolyline(markerLatLng, polylineLatLngs) {
      var nearestIndex = 0,
          nearestDistance;
          //markerLatLng = latLng,//marker.getLatLng(),
          //polylineLatLngs = polyline.getLatLngs();
      for (var i = 0; i < polylineLatLngs.length; i++) {
        var distance = markerLatLng.distanceTo(polylineLatLngs[i]) //distance en mètres
        if (!nearestDistance || nearestDistance > distance) {
          nearestIndex = i
          nearestDistance = distance
        }
      }
      return polylineLatLngs[nearestIndex];
    },
    

    
    async loadStreetView(lat, lng) {
      var iframe = document.createElement('iframe');
      iframe.src = `https://www.google.com/maps/embed/v1/streetview?location=${lat},${lng}&key=${this.apiKey}`;
      iframe.class = 'streetview';
      iframe.style = 'border:0;width:100%;height:100%'
      iframe.allowfullscreen = 'allowfullscreen';
      this.$refs.streetviewPlaceholder.textContent = '';
      this.$refs.streetviewPlaceholder.appendChild(iframe);
    },
  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race || !this.result ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'race', params: {id: this.event.id, raceId: this.race.id}} },
        { text: this.result.name, disabled: true },
        { text: 'StreetView', disabled: true },
      ];
    },
    isEmbedded() {
      return this.embed || this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    }),

  },

};
</script>
<style lang="scss">
  .mapContainer .intro-text { display: none;}
  .streetviewContainer iframe {
    min-height: 500px;
  }
</style>

