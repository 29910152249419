<template>
  <div>
    <v-dialog v-model="isModal" :fullscreen="$vuetify.breakpoint.xs" @input="v => v || close()" :persistent="photoGalleryIsFullScreen">
      <!-- <v-btn id="closeDialog" text large color="white" @click="isModal = false"><v-icon class="mr-2">fa-times-circle</v-icon> {{$t('shared.close')}}</v-btn> -->
      <v-card v-if="resultEvent && resultRace">  
        <RaceResultDetails :event="resultEvent" :race="resultRace" :meta="meta" :result="result" :filter="filter" :badges="resultBadges" :adminMode="adminMode" :focusSection="focusSection" popupMode @closeClick="close" @photoGalleryOpen="photoGalleryIsFullScreen=true" @photoGalleryClose="photoGalleryIsFullScreen=false"/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="isModal = false">{{$t('shared.close')}}</v-btn>
        </v-card-actions>      
      </v-card>
    </v-dialog>    
  </div>
</template>

<script>
import eventService from "@/services/eventService";
import RaceResultDetails from "@/components/RaceResultDetails.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "RaceResultDetailsDialog",
  components: {
    RaceResultDetails,
  },
  props: {
      event: Object,
      race: Object,
      meta: Object,
      badges: Array,
      filter: Object,
      adminMode: Boolean,
  },
  data() {
    return {
      isModal: false,
      result: null,
      resultRace: null,
      resultEvent: null,
      resultBadges: null,
      focusSection: null,
      photoGalleryIsFullScreen: false,
    };
  },
  async mounted() {
  },
  methods: {
    async close() {
      this.isModal = false;
      if (!this.adminMode) {
        const { href } = this.$router.resolve({ name: 'race', params: { id: this.event.id, raceId: this.race.id} }); 
        window.history.pushState({}, null, href); 
      }
      this.$emit('close');
    },
    async open(result, event, race, badges, section) {
      this.isModal = true;
      this.resultEvent = event || this.event;
      this.resultRace = race || this.race;
      this.resultBadges = badges || this.badges;
      this.focusSection = section;
      this.result = null;
      if (result.data == null) {
        result = (await eventService.getRaceResultDetails(this.resultEvent.id, this.resultRace.id, result.id, this.filter)).data;
      }
      this.result = result;

      
      var meta = {
        title: `${result.name} at the ${this.race.name} in the ${this.event.name} on ${tenant.name}`, 
        description: this.event.description,
        image: this.event.img,
      }; 
      EventBus.$emit('page-header-change', meta);

      if (!this.adminMode) {
        const { href } = this.$router.resolve({ name: 'raceResult', params: { id: this.event.id, raceId: this.race.id, resultId: result.id } }); 
        window.history.pushState({}, null, href); 
      }
    }
  },
  computed: {
  },

};
</script>
<style lang="scss">
#closeDialog {
  position: absolute;
  margin-top: -40px;
  right: 15px;
  width: auto;
}
</style>

